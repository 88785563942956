<template>
  <v-container class="fill-height" fluid>
    <v-row v-if="isMobile && !isMobilePaysage" class="">
      <v-col cols="12" id="calendar-view">
        <v-sheet height="64" class="mt-n3" color="transparent" id="toolbar">
          <v-toolbar flat>
            <v-btn-toggle v-model="toggle_type" color="primary">
              <v-btn small>
                M/J
              </v-btn>
            </v-btn-toggle>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small color="primary">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small color="primary">mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title
              color="black"
              class="d-flex align-baseline small"
              v-html="smalltitle"
            >
            </v-toolbar-title>
            <v-card-subtitle class="mr-n12">{{
              this.eqpt.substring(0, this.eqpt.length - 3)
            }}</v-card-subtitle>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="calc(100% + 64px)">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekdayDirect"
            :interval-height="52"
            :event-height="25"
            color="primary"
            locale="fr"
            :type="types[toggle_type]"
            :interval-style="styleInterval"
            :event-name="eventName"
            :events="allEvents"
            :event-color="getEventColor"
            :short-weekdays="true"
            @change="updateRange"
            :first-interval="7"
            :interval-count="15"
            :interval-format="intervalFormat"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:day="viewDay"
            @click:date="viewWeek"
            @touchend:event="startDrag"
            @touchstart:event="show"
            @touchstart:time="startTime"
            @touchmove:time="mouseMove"
            @touchend:time="endDrag"
            @mouseleave.native="cancelDrag"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <ReservationItemMenu
              :selectedEvent="selectedEvent"
              :reservations="reservations"
              :progress="round(progressValue)"
              :loadingText="loadingText"
              @deleteReservation="deleteReservation"
              @updateReservation="updateReservation"
              @updateRecursiveReservation="updateRecursiveReservations"
              :dragData="dragData"
              @closeSelected="close"
              :overlayStatus="overlay"
            ></ReservationItemMenu>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row v-else-if="isMobilePaysage && isMobile">
      <v-col cols="12" id="calendar-view">
        <v-sheet class="mt-n12" color="transparent" id="toolbar">
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-card-title>{{
              this.eqpt.substring(0, this.eqpt.length - 3)
            }}</v-card-title>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="toggle_type" color="primary">
              <v-btn small>
                M/J
              </v-btn>
            </v-btn-toggle>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small color="primary">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small color="primary">mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title
              color="black"
              class="d-flex align-baseline small"
              v-html="smalltitle"
            >
            </v-toolbar-title>
          </v-toolbar>
        </v-sheet>
        <v-sheet class="mt-n2" height="calc(100% + 200px)">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="days"
            :interval-height="52"
            :event-height="25"
            color="primary"
            locale="fr"
            :type="types[toggle_type]"
            :interval-style="styleInterval"
            :event-name="eventName"
            :events="allEvents"
            :event-color="getEventColor"
            :short-weekdays="true"
            @change="updateRange"
            :first-interval="7"
            :interval-count="15"
            :interval-format="intervalFormat"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:day="viewDay"
            @click:date="viewWeek"
            @touchend:event="startDrag"
            @touchstart:event="show"
            @touchstart:time="startTime"
            @touchmove:time="mouseMove"
            @touchend:time="endDrag"
            @mouseleave.native="cancelDrag"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <ReservationItemMenu
              :selectedEvent="selectedEvent"
              :reservations="reservations"
              :progress="round(progressValue)"
              :loadingText="loadingText"
              @deleteReservation="deleteReservation"
              @updateReservation="updateReservation"
              @updateRecursiveReservation="updateRecursiveReservations"
              :dragData="dragData"
              @closeSelected="close"
              :overlayStatus="overlay"
            ></ReservationItemMenu>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row v-else class="">
      <v-col cols="12" id="calendar-view">
        <v-sheet height="64" class="mt-n3" color="transparent" id="toolbar">
          <v-toolbar flat>
            <v-btn-toggle v-model="toggle_type" color="primary">
              <v-btn small>
                Mois/Jour
              </v-btn>
            </v-btn-toggle>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small color="primary">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small color="primary">mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title color="black" v-html="title"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-card-subtitle>{{
              this.eqpt.substring(0, this.eqpt.length - 3)
            }}</v-card-subtitle>
            <v-spacer></v-spacer>
            <v-btn outlined color="grey darken-2" @click="setToday">
              {{ $t("calendar.today") }}
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="110%">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :interval-height="48"
            color="primary"
            locale="fr"
            :type="types[toggle_type]"
            :interval-style="styleInterval"
            :event-name="eventName"
            :events="allEvents"
            :event-color="getEventColor"
            :short-weekdays="false"
            @change="updateRange"
            :first-interval="7"
            @click:event="showEvent"
            @click:date="viewWeek"
            :interval-count="15"
            :interval-format="intervalFormat"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
          >
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :close-on-click="false"
            :activator="selectedElement"
            offset-x
          >
            <ReservationItemMenu
              :selectedEvent="selectedEvent"
              :reservations="reservations"
              :progress="round(progressValue)"
              :loadingText="loadingText"
              @deleteReservation="deleteReservation"
              @updateReservation="updateReservation"
              @updateRecursiveReservation="updateRecursiveReservations"
              :dragData="dragData"
              @closeSelected="close"
              :overlayStatus="overlay"
            ></ReservationItemMenu>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog
      ref="crudDialog"
      v-model="addEventDialog"
      persistent
      max-width="600px"
    >
      <add-reservation-form
        v-if="!updateDialog"
        :reservationItems="reservationItems"
        :eventLoading="eventLoading"
        :event="event"
        :overlay="overlay"
        :progress="round(progressValue)"
        @canceled="closeEventDialog"
        @addReservation="addReservation"
        @addRecursiveReservation="addRecursiveReservations"
      ></add-reservation-form>
    </v-dialog>
    <v-overlay :value="overlay">
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          indeterminate
          size="64"
          v-if="progressValue == 0"
        ></v-progress-circular>
        <v-progress-circular
          v-else
          :rotate="360"
          :size="100"
          :width="15"
          indeterminate
          color="error"
        >
          <span class="white--text">
            <strong>{{ round(progressValue) }} %</strong>
          </span>
        </v-progress-circular>
        <span class="mt-5"> {{ loadingText || "Loading" }}... </span>
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
import TICKET_API from "../api/ticket";
import moment from "../plugins/moment";
import { capitalize, shouldDisplay, uniqueElementBy } from "../utils";
import { mapGetters } from "vuex";
import { convert } from "../utils/icsToJson";
import { PORTAL_CONFIG } from "../config";
import { EventBus } from "../event-bus";
import AddReservationForm from "../components/calendar/AddReservation.vue";
import ReservationItemMenu from "../components/calendar/ReservationItemMenu.vue";
import {
  CalendarDragableMixin,
  BaseCalendarMixin,
  CalendarCrudMixin
} from "../mixins/calendar";

export default {
  name: "Calendar",
  components: {
    AddReservationForm,
    ReservationItemMenu
  },
  mixins: [CalendarDragableMixin, BaseCalendarMixin, CalendarCrudMixin],

  data: () => ({
    reservations: [],
    reservationItems: [],
    weekdayDirect: [1, 2, 3, 4, 5],
    days: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    events2: [],
    icsEvents: [],
    icsCalendarUid: [],
    eqpt: "",
    time: ["days", "weeks", "months"],
    repeatEventItems: [],
    overlay: false,
    updateDialog: false
  }),
  methods: {
    loadData() {
      if (!this.safariAgent) {
        TICKET_API.getReservationItems().then(({ data }) => {
          let prevLgt = 0;
          const totalColors = this.colors.length;
          let reservationItems = data;
          TICKET_API.getReservations().then(({ data }) => {
            reservationItems.forEach(async (item, i) => {
              let reservations = data.filter(
                el => el.reservationitems_id === item.id
              );
              let response = await TICKET_API.getReservationItem(
                item.itemtype,
                item.items_id
              );

              let reservationItem = response.data;
              let name = await reservationItem.name;

              reservationItem.itemtype = item.itemtype;
              reservationItem.items_id = item.items_id;
              reservationItem.reservationitems_id = item.id;
              this.reservationItems.push(reservationItem);
              reservations.forEach(res => {
                if (item.items_id != prevLgt) {
                  this.eqpt =
                    this.eqpt + reservationItem.name.slice(0, 6) + "/";
                }
                item.items_id = prevLgt;
                //if (this.events.length < 15) {
                if (shouldDisplay(res)) {
                  this.events.push({
                    name: `${name} -
                  ${
                    JSON.parse(res.comment).guest
                      ? JSON.parse(res.comment).guest
                      : JSON.parse(res.comment).email
                  }`,
                    start: moment(res.begin).format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(res.end).format("YYYY-MM-DD HH:mm:ss"),
                    color:
                      JSON.parse(res.comment).state == 0
                        ? "rgb(155, 155, 155)"
                        : this.colors[i % totalColors],
                    comment:
                      JSON.parse(res.comment).commercial == true
                        ? (JSON.parse(res.comment).comment
                            ? JSON.parse(res.comment).comment
                            : "") + " - Usage commercial"
                        : JSON.parse(res.comment).comment,
                    tel: JSON.parse(res.comment).tel,
                    email: JSON.parse(res.comment).email,
                    guest: JSON.parse(res.comment).guest,
                    id: res.id
                  });
                }

                //}
              });
              this.$refs.calendar.checkChange();
              this.reservations = this.reservations.concat(reservations);
            });
          });
        });
      }
    },
    loadCalendar() {
      this.calendarUrl.forEach(url => {
        fetch(url, {
          headers: {
            "Content-Type": "text/calendar; charset=utf-8"
          }
        })
          .then(response => {
            return response.text();
          })
          .then(data => {
            let icsCalendar = convert(data);

            if (icsCalendar && icsCalendar.VCALENDAR) {
              const calendar = icsCalendar.VCALENDAR[0];
              const totalColors = this.icsColors.length;
              if (calendar.VEVENT) {
                calendar.VEVENT.forEach(res => {
                  if (!this.icsCalendarUid.includes(res.UID))
                    this.icsCalendarUid.push(res.UID);
                  this.icsEvents.push({
                    name: res.DESCRIPTION,
                    start: moment(res.DTSTART).format("YYYY-MM-DD HH:mm"),
                    end: moment(res.DTEND).format("YYYY-MM-DD HH:mm"),
                    color: this.icsColors[
                      this.getIcsColor(res.UID) % totalColors
                    ],
                    comment: res.SUMMARY || ""
                  });
                });
              }
            }
          });
      });
    },
    directionQrCode(direction) {
      return `${PORTAL_CONFIG.SERVERLESS_URL}/calendar?direction=${direction}&uuid=${this.uniqID}`;
    },
    getIcsColor(Uid) {
      return this.icsCalendarUid.findIndex(c => c === Uid);
    },
    resetDialogData() {
      this.dialog = false;
      this.overlay = false;
      this.addEventDialog = false;
      this.progressValue = 0;
      this.loadingText = undefined;
    },
    init() {
      if (this.getUserProfileStatus && this.isActiveEntityLoaded) {
        this.loadData();
      }
    }
  },

  mounted() {
    this.init();
    /*
    if (this.getPersonalToken) {
      this.loadCalendar();
    }
*/
    EventBus.$on("calendar", data => {
      if (data.direction === "next") {
        this.next();
      } else {
        this.prev();
      }
    });
  },
  computed: {
    ...mapGetters([
      "isActiveEntityLoaded",
      "getUserId",
      "getGLPGroup",
      "getPersonalToken",
      "getUserToken",
      "getSessionToken",
      "getUUID",
      "getUserProfileStatus",
      "getEcranModule"
    ]),
    calendarUrl() {
      //return `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=${this.getUserId}&gID=0&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`;
      return [
        `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=${this.getUserId}&gID=0&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`,
        `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=0&gID=${this.getGLPGroup}&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`
      ];
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const date = moment(start.date).format("MMMM YYYY");
      const week = moment(start.date).week();

      return `${capitalize(
        date
      )} / <span style="color: red">Semaine ${week}</span>`;
    },
    smalltitle() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const date = moment(start.date).format("MM/YY");
      const week = moment(start.date).week();

      let smalltitle = `<h4>${capitalize(date)}</h4>`;

      return this.toggle_type === 2
        ? smalltitle
        : `${smalltitle}<h5 style="color: white">_</h5><h5 style="color: red">S${week}</h5>`;
    },
    qrCodeUrl() {
      /*const link = this.isDirectView
        ? `piresa.php?user=${this.$route.params.userToken}&entity=${this.$route.params.id}`
        : `resa.php?user=${this.$route.params.userToken}&entity=${this.$route.params.id}`;
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/${link}`;*/
      return `${PORTAL_CONFIG.WEB_URL}/view/calendar/${this.$route.params.id}/u/${this.$route.params.userToken}`;
    },
    allEvents() {
      return this.uniqEvents.concat(this.events2).map(event => {
        let start = moment(event.start).format("DD MMMM YYYY à HH:mm:ss");
        let end = moment(event.end).format("DD MMMM YYYY à HH:mm:ss");
        const details = `<table class="event--table"><tr><td>Debut:</td> <td>${start}</td></tr><tr><td>Fin:</td><td>${end}</td></tr></table>`;
        event.details = details;
        event.timed = true;
        return event;
      });
    },
    uniqEvents() {
      return uniqueElementBy(
        this.events.concat(this.icsEvents),
        (a, b) => a.start == b.start && a.name == b.name
      );
    },
    isDirectView() {
      return this.$route.meta.directView;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    isMobilePaysage() {
      return this.$vuetify.breakpoint.height < this.$vuetify.breakpoint.width;
    },
    sessionUUID() {
      return this.getEcranModule && this.getEcranModule.qr_dyn == 1
        ? this.getUUID
        : "pilot-immot-app";
    },
    uniqID() {
      return this.$route.params.id
        ? `${this.sessionUUID}-${this.$route.params.id}`
        : null;
    }
  },
  watch: {
    /*icsCalendar() {
      if (this.icsCalendar && this.icsCalendar.VCALENDAR) {
        const calendar = this.icsCalendar.VCALENDAR[0];
        const totalColors = this.icsColors.length;
        calendar.VEVENT.forEach((res, index) => {
          this.icsEvents.push({
            name: res.SUMMARY,
            start: this.formatDate(new Date(moment(res.DTSTART).format())),
            end: this.formatDate(new Date(moment(res.DTEND).format())),
            color: this.icsColors[index % totalColors]
          });
        });
      }
    },*/
    getUserProfileStatus() {
      this.init();
    },
    isActiveEntityLoaded() {
      this.init();
    }
    /*,
    getPersonalToken() {
      if (this.getPersonalToken) {
        this.loadCalendar();
      }
    }*/
  }
};
</script>

<style>
.pl-1 {
  font-size: 0.9rem;
}
#calendar-view {
  position: absolute;
  top: 0;
  bottom: 110px;
  height: calc(100% - 96px);
}
#calendar-view #toolbar header {
  background-color: transparent;
  position: relative;
  z-index: 3;
}
.v-calendar-weekly__head {
  height: 50px;
  padding-top: 5px;
  background-color: #f7f7f7;
}
.v-calendar-weekly__head-weekday {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
